html, body{
  margin: 0;
  font-family:"Roboto", Geneva;
  line-height: 24px;
  font-size: 15px;;
}

h1{
  font-size: 40px;
}

h2{
  font-size: 30px;
}

h4{
  color: #123f55;
  font-weight: bold;
  line-height: 5px;
}


.top{
  height: 60px;
  padding-right: 30px;
}

.top a{
  float: right!important;
}

.banner{
  position: relative;
}

.banner img{
  width: 100%;
}

.banner h1{
  position: absolute;
  top: 30%;
  left: 5%;
  color: #FFFFFF;
}

.banner h2{
  position: absolute;
  top: 30%;
  left: 5%;
  color: #FFFFFF;
}

.intro{
  width: 90%;
  margin: 30px auto;
}

.list-type{
  width:90%;
  margin:0 auto;
}
  
.list-type ul{
  counter-reset: li;
  list-style: none;
  *list-style: decimal;
  font-size: 15px;
  padding: 0;
  margin-bottom: 4em;
}
  
.list-type li{
  position: relative;
  display: block;
  padding: .6em 2em 1.5em 2em;
  margin: .5em 0;
  background: #d9edf7;
  color: #31708f;
  text-decoration: none;
  -webkit-transition: box-shadow 1s; /* For Safari 3.1 to 6.0 */
  transition: box-shadow 1s;
}

.footer{
  text-align: center;
  background-color: #f1f1f1;
  padding-top: 30px;
  padding-bottom: 30px;
  bottom: 0;
  left: 0;
  width: 100%;
  position: fixed;
  font-size: 13px;
}